<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-10">
              <h3 class="mb-0">
                {{ cashRegister.name }}
                <base-button
                  v-if="$currentUserCan($permissions.PERM_EDIT_CASH_REGISTERS)"
                  type="primary"
                  icon
                  size="sm"
                  @click="editCashRegister()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-edit"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="
                    $currentUserCan($permissions.PERM_DELETE_CASH_REGISTERS)
                  "
                  type="danger"
                  icon
                  size="sm"
                  @click="deleteCashRegister()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-trash"></i>
                  </span>
                </base-button>

                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="goToCashRegisesterReading"
                  v-if="
                    $currentUserCan($permissions.PERM_CREATE_CASH_REGISTERS)
                  "
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-plus"></i>
                  </span>
                  <span class="btn-inner--text">
                    {{ $t("CASH_REGISTER_READINGS.ADD_CASH_REGISTER_READING") }}
                  </span>
                </base-button>
              </h3>
            </div>
            <div class="col-2 text-right">
              <base-button
                @click="goBack()"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
        </template>
        <div>
          <div
            class="col-12 justify-content-center justify-content-sm-between flex-wrap"
          >
            <tabs
              fill
              class="flex-column flex-md-row"
              tabNavWrapperClasses="nav-wrapper"
              tabNavClasses="nav nav-pills nav-fill"
              value="global"
            >
              <card shadow>
                <tab-pane title="global" id="1" :active="true">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.GLOBAL") }}
                  </span>
                  <cash-register-view-global
                    :cashRegister="cashRegister"
                    @cashRegisterUpdated="get"
                  />
                </tab-pane>
                <tab-pane title="cashRegisterReadings" id="2">
                  <span slot="title">
                    <i class="fa fa-calculator" />
                    Lecture de Caisse
                  </span>
                  <cash-register-view-reading
                    v-if="cashRegister.id"
                    :filterOrganization="cashRegister.organization?.id"
                    :cashRegister="cashRegister.id"
                    @cashRegisterUpdated="get"
                  />
                </tab-pane>

                <tab-pane
                  title="logs"
                  id="999"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
                >
                  <span slot="title">
                    <i class="fa fa-file" />
                    {{ $t("COMMON.LOGS") }}
                  </span>
                  <cash-register-view-logs :cashRegister="cashRegister" />
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import { Tabs, TabPane } from "@/components";
//import AllowedUsersView from "@/components/AllowedUsers/AllowedUsersView.vue";
import defaultCashRegister from "./defaultCashRegister";
import CashRegisterViewGlobal from "./partials/CashRegisterViewGlobal.vue";
// import ServiceCenterViewEstablishments from "./partials/ServiceCenterViewEstablishments.vue";
import CashRegisterViewLogs from "./partials/CashRegisterViewLogs.vue";
// import ServiceCenterViewContacts from "./partials/ServiceCenterViewContacts.vue";
import CashRegisterViewWarehouseProducts from "./partials/CashRegisterViewWarehouseProducts.vue";
import CashRegisterViewReading from "./partials/CashRegisterViewReading.vue";
export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    CashRegisterViewGlobal,
    CashRegisterViewWarehouseProducts,
    CashRegisterViewLogs,
    CashRegisterViewReading,
    // ServiceCenterViewEstablishments,
    // AllowedUsersView,
    //ServiceCenterViewContacts,
  },

  mixins: [],

  data() {
    return {
      cashRegister: cloneDeep(defaultCashRegister),
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("cashRegisters/get", id);
        this.cashRegister = this.$store.getters["cashRegisters/cashRegister"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async editCashRegister(row) {
      this.$router.push({
        name: "Edit CashRegister",
        params: { id: this.cashRegister.id },
      });
    },

    async deleteCashRegister() {
      const confirmation = await swal.fire({
        title: this.$t("CASH_REGISTERS.DELETE_THIS_CASH_REGISTER"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "cashRegisters/destroy",
            this.cashRegister.id
          );
          await this.goBack();
          this.$notify({
            type: "success",
            message: this.$t("CASH_REGISTERS.CASH_REGISTER_DELETED"),
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    goToCashRegisesterReading() {
      this.$router.push({ name: "List CashRegisterReadings" });
    },
    goBack() {
      this.$router.push({ name: "List CashRegisters" });
    },
  },
};
</script>
